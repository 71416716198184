import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Superbowl Customer Story - Rotaready"
          description="Here's how Superbowl UK have been able to better deploy over 45 hours each week since using Rotaready, and achieve an ROI of 556%"
          url="customer-stories/leisure-superbowl-uk"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Superbowl UK"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Leisure" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Superbowl UK logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how Superbowl UK have been able to better deploy over 45 hours each week since using Rotaready, and achieve an ROI of 556%" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="12" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="300" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="556%" />
              <StatSubtitle text="return on investment" />
            </StatItem>

            <StatItem>
              <StatHeadline text="45 hours" />
              <StatSubtitle text="better deployed every week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Superbowl UK" />
              </Content>

              <Content>
                <TextBlock text="Superbowl UK are a family entertainment centre. Superbowl UK have 12 sites across the UK, each of which boast a range of facilities including bowling, adventure golf, arcade amusementsand Laser Quest. When the business opened in 2012, the team were building staff rotas and monitoring attendance using spreadsheets and sharing rotas via Whatsapp; an approach they persisted with for a number of years." />
              </Content>

              <Content>
                <TextBlock text="But as the business expanded, it became clear that these manual processes, being both inefficient and un-resourceful, weren't sustainable." />
              </Content>

              <Content>
                <Blockquote attribution="Liam Clarke, Assistant National Operations Manager">
                  As we grew, we needed more efficiency. We wanted to make sure staff were getting their shifts in advance, make holiday tracking easier and improve our <Link to='/payroll' style="color:inherit;">payroll</Link> processes. The more automation we could have the better.
                </Blockquote>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Improving accuracy & efficiency" />
              </Content>

              <Content>
                <TextBlock>
                So in January 2022, the team at Superbowl UK set about their search for a feature rich workforce management solution that could help them ditch their manual processes and generally improve operational efficiency. More specifically, the business wanted a solution to help them improve <Link style="color:inherit;" to="/payroll">payroll</Link> processes, track time & attendance and a better way of building and sharing staff rotas.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="After reviewing several options and upon recommendation from an industry peer, Superbowl UK chose Rotaready as their staff scheduling partner." />
              </Content>

              <Content>
                <Blockquote
                  text="We chose Rotaready for a number of reasons. The cost was reasonable, the product was flexible and it had the functionality to eradicate our manual processes. Sickness management and holiday tracking for example, were taking up the majority of a manager's morning and now that's all automated. It's made everybody's lives so much easier."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="From making the decision to go with Rotaready, all 12 sites were set up with the rota software and staff fully trained, within Superbowl UK's desired time frame of two months." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Enhanced visibility" />
              </Content>

              <Content>
                <TextBlock text="Since then, the team at Superbowl UK have seen a number of benefits as a direct result of using Rotaready. One of the main ones being enhanced visibility..." />
              </Content>

              <Content>
                <TextBlock text="The head office team now has full visibility of contractual obligations, holiday requests and pay all in one place. This has helped them ensure employees are working the correct number of hours, are always paid correctly and any holiday or absences are tracked accurately." />
              </Content>

              <Content>
                <TextBlock text="Head office also now has full visibility of rotas, which helps them ensure managers are scheduling their labour correctly in line with demand." />
              </Content>

              <Content>
                <Blockquote
                  text="There's a second line of defence for rotas being done at site level, we have the visibility to make sure mistakes aren't made that we could potentially avoid. And people can see any amendments as soon as they are done, it's made being accurate easy."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Rotaready has also helped massively with assessing performance per site. Its Cost Control feature enables the Superbowl UK team to see which centres are performing or underperforming, helping them make more informed scheduling decisions. And with customisable labour streams they can also see wage percentages per site." />
              </Content>

              <Content>
                <Blockquote
                  text="Being able to add additional labour streams has meant we can see wage percentage per department for the first time in a number of years. We can accurately identify where a site might be understaffed or underperforming and make informed decisions on what we can do to help them, rather than just ballpark guessing."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Visibility has also improved for employees as they can now see upcoming shifts, holiday requests and allowances from within the Rotaready app. Previously this information could only be found in various paper formats or via WhatsApp." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Hours to minutes" />
              </Content>

              <Content>
                <TextBlock text="Having this improved visibility has also helped the team at Superbowl UK save a significant amount of time that was previously being wasted on manually tracking down the right information, cross checking attendance data, confirming availability and building rotas." />
              </Content>

              <Content>
                <Blockquote
                  text="The value of time saved on day to day has been absolutely phenomenal. We're not chasing things like incorrect timesheets or availability anymore. The only thing we chase is for rotas to be signed off, that's it. What was a few hours has become 10 minutes. The workload it has taken away from managers has been fantastic."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="In fact, Superbowl UK has been able to save over 45 hours a week and achieve an ROI of 556% as a direct result of using Rotaready." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Standout features" />
              </Content>

              <Content>
                <TextBlock text="Whilst Superbowl UK have seen these benefits as a result of using the majority of Rotaready's features, we all have our favourites. Here are the team's top three features..." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="1. Contractual obligations reports" />
                <H4 uistyle="primary" text="2. Rota approval" />
                <H4 uistyle="primary" text="3. Absence booking" />
              </Content>

              <Content>
                <Blockquote
                  text="Booking holiday used to be on sheets of paper and you had to wait a few days for it to be signed off. But now everyone can do it easily via Rotaready. And at head office we can make sure everyone is taking the annual leave they're entitled to without there being too many people off at once."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Bowling lanes at a Superbowl UK venue"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Continuous collaboration" />
              </Content>

              <Content>
                <TextBlock text="Going forward Rotaready's customer success team are continuing to work closely with Superbowl UK to help the team leverage all the benefits of the platform and ensure the smooth rollout of any future sites." />
              </Content>

              <Content>
                <Blockquote
                  text="The support we get from Rotaready is always exceptional. It's honestly 10/10 every time I get in touch. They have solutions to any problems within 10 minutes."
                />
              </Content>

              <Content>
                <Blockquote
                  text="And it's not just reactive, our Customer Success Manager catches up with us every two months to check in on how we're utilising the software and suggests any new features we could be benefiting from. The care and attention that has been given to us is absolutely fantastic."
                  attribution="Liam Clarke, Assistant National Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Signage on the exterior of a Superbowl UK venue"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_superbowl"
                label="rotaready.com/customer-stories/leisure-superbowl-uk"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Superbowl-UK.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/superbowl/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/superbowl/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/superbowl/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/superbowl/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
